<template>
	<div class="container">
		<h2 class="label-header" style="margin-bottom: 15px;">Exam Year</h2>
		<button class="btn btn-primary mb-3 mt-1" @click="openModal" :disabled="isLoading">Add Year</button>
		<div class="alert alert-danger alert-dismissable" v-if="showErrorPageAlert">
			{{ errorMessage }}
		</div>
		<div class="card">
			<div class="card-body" v-if="!isLoading">
				<table class="table table-hover">
					<thead>
						<tr>
							<th>Year name</th>
							<th style="width: 200px;" v-if="parseInt(role) === 1">Action</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="yearList.length > 0">
							<tr v-for="(item, i) in yearList" :key="i">
								<td>{{ item.year_name }}</td>
								<td style="width: 200px;" v-if="parseInt(role) === 1">
									<button class="btn btn-sm btn-primary" style="margin-right: 10px;" @click="editItem(item)">Edit</button>
									<button class="btn btn-sm btn-danger" @click="deleteItem(item)">Delete</button>
								</td>
							</tr>
						</template>
						<template v-if="yearList.length === 0">
							<tr>
								<td style="text-align: center" colspan="2" v-if="parseInt(role) === 1">No Exam Year Added Yet</td>
								<td style="text-align: center" colspan="1">No Exam Year Added Yet</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
		<div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
						<button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="alert alert-danger alert-dismissable" v-if="showErrorAlert">
							{{ errorMessage }}
						</div>
						<form @submit.prevent="onSubmit" novalidate>
							<div class="form-group mb-2">
								<label for="">Question/Exam year Code</label>
								<input type="text" v-model="vv.year.$model" placeholder="Enter year" class="form-control">
								<span class="error">{{ vv?.year?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group">
								<button class="btn btn-primary" :disabled="vv.$invalid || isProcessing" style="margin-right: 15px;" v-if="editMode">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Updating.....</span>
									<span v-if="!isProcessing">Update</span>
								</button>
								<button class="btn btn-primary" :disabled="vv.$invalid || isProcessing" v-if="!editMode">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Saveing.....</span>
									<span v-if="!isProcessing">Save</span>
								</button>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="modal.hide()">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Modal } from 'bootstrap'
import { projectService } from '@/services/project.service'
import { storageService } from '@/services/storage.service'

export default {
	name: 'ExamYear',
	setup() {
		const fform = reactive({
			year: ''
		})
		const rules = {
			year: { required }
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isLoading: true,
			isProcessing: false,
			loadingMessage: 'Loading Exam Year ........',
			showErrorAlert: false,
			showErrorPageAlert: false,
			errorMessage: '',
			modalTitle: 'Add Exam Year',
			modal: null,
			editMode: false,
			editIndex: 0,
			yearList: [],
			role: 0
		};
	},
	mounted() {
		this.modal = new Modal(this.$refs.exampleModal)
		this.loadContent()
		this.isUserAdmin()
	},
	methods: {
		loadContent () {
			this.isLoading = true
			const folder = this.$route.params.folder
			projectService.fetchProjectFile(`${folder}/exam_year.json`)
				.then((response) => {
					this.isLoading = false
					this.yearList = response.data
				})
				.catch((error) => {
					console.log(error.message)
				})
		},
		async isUserAdmin() {
			const role = await storageService.isUserAdmin()
			this.role = role
		},
		openModal () {
			this.modalTitle = 'Add Exam Year'
			this.editMode = false
			this.fform.year = ''
			this.modal.show()
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.showErrorAlert = false
			this.isProcessing = true
			if(this.editMode){
				const postData = {
					year_id: this.editIndex,
					folder: this.$route.params.folder,
					year_code: this.fform.year
				}
				projectService.updateYear(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.modal.hide()
							this.fform.year = ''
							this.loadContent()
						}else{
							this.showErrorAlert = true
							this.errorMessage = response.data.message
						}
					})
					.catch((error) => {
						console.log(error.message)
						this.isProcessing = false
						this.showErrorAlert = true
						this.errorMessage = 'Error Connecting To Server'
					})
			}else{
				const postData = {
					folder: this.$route.params.folder,
					year: this.fform.year
				}
				projectService.createYear(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.modal.hide()
							this.fform.year = ''
							this.loadContent()
						}else{
							this.showErrorAlert = true
							this.errorMessage = response.data.message
						}
					})
					.catch((error) => {
						console.log(error.message)
						this.isProcessing = false
						this.showErrorAlert = true
						this.errorMessage = 'Error Connecting To Server'
					})
			}
		},
		editItem (item) {
			this.fform.year = item.year_name
			this.editIndex = item.year_id
			this.editMode = true
			this.modalTitle = 'Edit Exam Year'
			this.modal.show()
		},
		deleteItem (item) {
			this.$swal({
				text: `Are you sure to delete this exam year?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, Delete',
				allowOutsideClick: false
			}).then((responses) => {
				if(responses.isConfirmed) {
					this.isLoading = true
					this.showErrorPageAlert = false
					const postData = {
						year_id: item.year_id,
						folder: this.$route.params.folder
					}
					projectService.deleteYear(postData)
						.then((response) => {
							this.isLoading = false
							if(parseInt(response.data.message_code) === 200){
								this.loadContent()
							}else{
								this.showErrorPageAlert = true
								this.errorMessage = response.data.message
							}
						})
						.catch((error) => {
							console.log(error.message)
							this.isLoading = false
							this.showErrorPageAlert = true
							this.errorMessage = 'Error Connecting To Server'
						})
				}
			})
		}
	}
};
</script>

<style scoped>
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>